import React from 'react';
import Image from 'next/image';

import { Paragraph, Wrap } from '@leafwell/components';

import { useWordPressContext } from 'contexts/wordpress-provider';

const TrustSignals: React.FC = () => {
  const { translations } = useWordPressContext();
  const badges = [
    {
      icon: '/trust-signals-value.svg',
      label: translations['Best Care, Best Value'],
      description:
        translations[
          'We are the #1 medical card certification platform with the best value in your state.'
        ],
    },
    {
      icon: '/trust-signals-google.svg',
      label: translations['4.9 out of 5 stars'],
      description:
        translations[
          'Patient support and satisfaction are at the heart of everything we do.'
        ],
    },
    {
      icon: '/trust-signals-be-seen.svg',
      label: translations['Be seen in under 10 mins'],
      description:
        translations[
          'Our healthcare providers are available to see you from 8 a.m. to 8 p.m. 7 days a week.'
        ],
    },
  ];
  return (
    <Wrap className="items-center">
      <div className="flex flex-col md:flex-row justify-between gap-x-2 lg:px-20 gap-y-8">
        {badges.map(({ label, icon, description }, idx) => {
          return (
            <article key={idx} className="grid justify-items-center">
              <span className="flex min-h-28 justify-center items-start lg:items-center">
                <Image
                  src={icon}
                  width={100}
                  height={88}
                  alt={label}
                  quality={50}
                />
              </span>
              <Paragraph className="text-xl text-center">{label}</Paragraph>
              <Paragraph className="max-w-xs text-xs xl:text-sm text-center">
                {description}
              </Paragraph>
            </article>
          );
        })}
      </div>
    </Wrap>
  );
};

export default TrustSignals;
