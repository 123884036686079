import type { Review, ReviewAuthor } from 'types';
import React from 'react';
import Head from 'next/head';
import { useWordPressContext } from 'contexts/wordpress-provider';

const ReviewJson: React.FC<
  Omit<Review, 'acf' | 'rating' | 'reviewAuthor'> & {
    rating: string;
    reviewAuthor: ReviewAuthor;
    globalRating?: number;
    reviewsCount?: number;
  }
> = ({
  id,
  date,
  content,
  featuredMedia,
  rating,
  reviewAuthor,
  globalRating,
  reviewsCount,
}) => {
  const { logo, reviewsSettings, siteTitle } = useWordPressContext();
  const details = {
    '@context': 'https://schema.org/',
    '@type': 'Review',
    datePublished: date,
    itemReviewed: {
      '@type': 'Organization',
      image: logo?.src,
      name: siteTitle,
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: globalRating || reviewsSettings?.rating,
        bestRating: reviewsSettings?.bestRating,
        worstRating: reviewsSettings?.worstRating,
        reviewCount: reviewsCount || reviewsSettings?.count,
      },
    },
    reviewRating: {
      '@type': 'Rating',
      ratingValue: rating,
    },
    author: {
      '@type': 'Person',
      image: featuredMedia?.src,
      name: reviewAuthor?.name,
    },
    reviewBody: content,
  };

  return React.createElement(
    Head,
    null,
    React.createElement('script', {
      type: 'application/ld+json',
      key: `leafwell-review-json-ld-${id}`,
      dangerouslySetInnerHTML: {
        __html: JSON.stringify(details),
      },
    }),
  );
};

export default ReviewJson;
