import type { BlockReviews } from 'types/block-reviews';
import type { PartnersReviewsResult } from 'lib/queries/reviews';
import type { ReviewsCarouselProps } from 'components/reviews-carousel';
import React from 'react';
import Reviews from 'components/reviews-carousel';
import TrustpilotCarousel from './trustpilot-carousel';
import TrustSignals from './trust-signals';

const ReviewsBlock: React.FC<
  BlockReviews & {
    pageType: string;
    reviews?: PartnersReviewsResult['partnersReviews'];
  }
> = ({
  title,
  intro,
  trustpilot,
  showTrustSignals,
  variant,
  quoted,
  pageType,
  sectionId,
  reviews,
}) => {
  if (reviews?.length === 0 && !trustpilot) {
    return null;
  }

  return (
    <>
      {trustpilot ? (
        <TrustpilotCarousel {...{ title, intro, sectionId }} />
      ) : (
        <Reviews
          center={false}
          items={reviews}
          title={title}
          intro={intro}
          hasTrustpilot={pageType === 'home'}
          variant={variant as ReviewsCarouselProps['variant']}
          quoted={quoted}
          sectionId={sectionId}
        />
      )}
      {showTrustSignals ? <TrustSignals /> : null}
    </>
  );
};

export default ReviewsBlock;
