import React from 'react';

import { Wrap } from '@leafwell/components';

import HeadingBody from './heading-body';

const TrustpilotCarousel: React.FC<{
  title?: string;
  intro?: string;
  sectionId?: string;
}> = ({ title, intro, sectionId }) => {
  return (
    <Wrap
      id={sectionId}
      className="md:w-full overflow-hidden mx-auto py-10 md:py-12"
      size="large"
    >
      <div className="text-center">
        {title?.length > 0 && (
          <HeadingBody className="!mb-2" {...{ level: '2', title }} />
        )}
        {intro?.length > 0 ? <p>{intro}</p> : null}
      </div>
      <div
        className="trustpilot-widget min-h-[140px]"
        data-locale="en-US"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="63caa6848fcd1c82bbeac42d"
        data-style-height="140px"
        data-style-width="100%"
        data-theme="light"
        data-stars="4,5"
        data-review-languages="en"
      >
        <a
          href="https://www.trustpilot.com/review/leafwell.com"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </Wrap>
  );
};

export default TrustpilotCarousel;
