import type { Media } from 'types';
import type { ImageProps } from 'next/image';
import React from 'react';
import Image from 'next/image';

export type FigureProps = Omit<Media, 'id' | 'srcSet'> &
  Pick<ImageProps, 'priority' | 'quality' | 'sizes'> & {
    className?: string;
    classNameImage?: string;
    objectFit?: 'none' | 'cover' | 'contain';
  };

function getImageProps(width: Media['width'], height: Media['height']) {
  // Little hack for when we don't have width and height - https://github.com/vercel/next.js/discussions/18474#discussioncomment-5501724
  // some svgs might come with incorrect width and height values (e.g. 1px)
  if ((!width && !height) || (width === 1 && height === 1)) {
    return {
      width: 0,
      height: 0,
      sizes: '33vw',
      classes: 'w-full h-full',
    };
  }

  return {
    width,
    height,
    classes: '',
  };
}

function getImageClassNames(objectFit?: 'cover' | 'contain' | 'none') {
  switch (objectFit) {
    case 'cover':
      return 'absolute top-0 left-0 h-full object-cover';
    case 'contain':
      return 'h-full object-contain';
    default:
      return 'h-auto';
  }
}

const Figure: React.FC<FigureProps> = ({
  src,
  width,
  height,
  alt = '',
  className = '',
  classNameImage = '',
  objectFit = 'none',
  priority = false,
  quality = 75,
  sizes = null,
}) => {
  const {
    width: imageWidth,
    height: imageHeight,
    classes: imageClasses,
    sizes: imageSizes = null,
  } = getImageProps(width, height);

  return (
    <figure className={['relative overflow-hidden', className].join(' ')}>
      <Image
        className={[
          getImageClassNames(objectFit),
          classNameImage,
          imageClasses,
        ].join(' ')}
        alt={alt}
        src={src}
        width={imageWidth}
        height={imageHeight}
        sizes={sizes || imageSizes}
        priority={priority}
        quality={quality}
      />
    </figure>
  );
};

export default Figure;
