import { useCallback } from 'react';
import { useUniqueId, Paragraph } from '@leafwell/components';
import { useWordPressContext } from 'contexts/wordpress-provider';

const Rating: React.FC<{
  bgColor?: string;
  highlightColor?: string;
  max?: number;
  rating: string;
  showLabel?: boolean;
  showTotalStars?: boolean;
  className?: string;
  name?: string;
  starsVariant?: 'small' | 'large';
}> = ({
  bgColor = '#D9D9D9',
  highlightColor = '#DC9238',
  max = 5,
  rating,
  showLabel = false,
  showTotalStars = true,
  className = '',
  name,
  starsVariant = 'small',
}) => {
  const { translations } = useWordPressContext();
  const gradientId = useUniqueId('rating-gradient');
  const symbolId = useUniqueId('rating');
  const getFill = useCallback(
    index => {
      if (Math.ceil(Number(rating)) === index && rating < index) {
        return `url(#${gradientId})`;
      }

      if (index > rating) {
        return bgColor;
      }

      return highlightColor;
    },
    [bgColor, gradientId, highlightColor, max, rating],
  );

  return (
    <div
      role="img"
      aria-label={`${name} ${
        translations['rating is ' + rating + ' out of ' + max]
      }`}
      className={['flex gap-x-1 items-center', className].join(' ')}
    >
      <svg className="absolute w-0 h-0" viewBox="0 0 14 13">
        <defs>
          <linearGradient id={gradientId}>
            <stop offset="50%" stopColor={highlightColor} />
            <stop offset="50%" stopColor={bgColor} />
          </linearGradient>
          <symbol viewBox="0 0 14 13" id={symbolId}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.8 1c.1-.4.3-.6.5-.7A1.4 1.4 0 0 1 8 .3c.3.1.4.3.5.6l.9 2.6c0 .1 0 .2.2.2l.2.1h2.9l.8.3.5.6a1.3 1.3 0 0 1-.5 1.5L11 7.8l-.2.2v.3l.9 2.6a1.3 1.3 0 0 1-.5 1.5 1.4 1.4 0 0 1-1.6 0l-2.3-1.6a.5.5 0 0 0-.5 0l-2.3 1.6a1.4 1.4 0 0 1-1.6 0l-.5-.7c-.1-.2-.1-.5 0-.8l.9-2.6a.4.4 0 0 0-.2-.5L.9 6.2l-.5-.6A1.3 1.3 0 0 1 1 4c.3-.2.5-.3.8-.3H4.8l.2-.3.8-2.6Z"
            />
          </symbol>
        </defs>
      </svg>
      {showLabel && (
        <Paragraph
          {...{
            'aria-hidden': true,
            className: 'font-medium whitespace-nowrap',
            size: 'small',
          }}
        >
          {rating}
        </Paragraph>
      )}
      {showTotalStars ? (
        [...Array(max).keys()].map(index => (
          <svg
            className={
              starsVariant === 'small'
                ? 'w-[14px] h-[13px]'
                : 'w-[18px] md:w-[30px] h-[18px] md:h-[30px]'
            }
            key={index}
          >
            <use href={`#${symbolId}`} fill={getFill(index + 1)}></use>
          </svg>
        ))
      ) : (
        <svg
          className={
            starsVariant === 'small'
              ? 'w-[14px] h-[13px]'
              : 'w-[18px] md:w-[30px] h-[18px] md:h-[30px]'
          }
        >
          <use href={`#${symbolId}`} fill={highlightColor}></use>
        </svg>
      )}
    </div>
  );
};

export default Rating;
