import type { Review } from 'types/review';
import React, { useState } from 'react';
import Image from 'next/image';
import { Avatar, Link, Paragraph } from '@leafwell/components';
import ReviewJson from './structured-data/review';
import Rating from './rating';
import { useWordPressContext } from 'contexts/wordpress-provider';

export type ReviewProps = Review & {
  className?: string;
  date: string | number;
  globalRating?: number;
  reviewsCount?: number;
  variant?: 'minimal' | 'full';
  quoted?: boolean;
};

const MAXIMUM_CONTENT_LENGTH_VISIBLE = 315;

const Review: React.FC<ReviewProps> = ({
  id,
  date,
  className = '',
  variant = 'minimal',
  quoted = false,
  content,
  featuredMedia,
  acf: { rating, reviewAuthor },
}) => {
  const isMinimal = variant === 'minimal';
  const { translations } = useWordPressContext();
  const [{ open, toggled }, toggle] = useState({
    open: false,
    toggled: false,
  });
  const hasTextClamped = content.length > MAXIMUM_CONTENT_LENGTH_VISIBLE;

  return (
    <article
      className={[
        'grid ',
        isMinimal
          ? 'embla-minimal-variant gap-y-6 md:gap-y-0 grid-cols-2-auto gap-x-6 opacity-40'
          : 'items-start gap-y-3 content-start grid-cols-2-auto gap-x-3',
        className,
      ].join(' ')}
    >
      <ReviewJson
        id={id}
        date={date}
        content={content}
        featuredMedia={featuredMedia}
        rating={rating}
        reviewAuthor={reviewAuthor}
      />
      <Avatar
        className={[
          'my-0 mx-auto lg:m-0',
          isMinimal ? 'order-last w-11 h-11 md:self-end' : 'self-start',
        ].join(' ')}
        image={featuredMedia ? featuredMedia.src : null}
        size={isMinimal ? 'xlarge' : 'medium'}
        label={reviewAuthor.name}
        width="80"
        height="80"
        Component={Image}
      />
      <footer
        className={
          isMinimal
            ? 'lg:col-start-2 order-last md:self-end'
            : 'col-start-2 self-center'
        }
      >
        <Paragraph
          className="font-medium"
          dangerouslySetInnerHTML={{ __html: reviewAuthor.name }}
        />
        {reviewAuthor?.location && (
          <Paragraph
            size="small"
            dangerouslySetInnerHTML={{ __html: reviewAuthor.location }}
          />
        )}
        {!isMinimal && (
          <div className="lg:flex gap-x-3 col-span-full items-center content-center">
            <Rating name={reviewAuthor.name} rating={rating} />
          </div>
        )}
      </footer>
      <div className="my-4 col-span-full">
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: quoted ? `“${content}”` : content,
          }}
          tagName="div"
          className={[
            isMinimal ? 'text-3xl sm:text-4xl' : '',
            !open && !toggled ? 'line-clamp-5' : '',
          ].join(' ')}
          variant={'default'}
        />
        {hasTextClamped && (
          <Link
            className="!underline text-sm lowercase mb-2"
            onClick={() => toggle({ open: !open, toggled: !toggled })}
            aria-hidden="true"
          >
            {open ? translations['Read less'] : translations['Read more']}
          </Link>
        )}
      </div>
    </article>
  );
};

export default Review;
